import React from 'react';
import './App.css';
import './rtl.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from './screens/LoginRegister/Login';
import WorkflowPendingTasks from './screens/Workflow Module Screen/Workflow Pending Tasks/WorkflowPendingTasks';
import Register from './screens/LoginRegister/Register';
import LogoutComponent from './components/Auth/LogoutComponent';
import Template from './screens/Workflow Module Screen/Template/Tempalte View/Template';
import FieldDictionary from './screens/Workflow Module Screen/Template/Template Edit/FieldDictionary';
import Workflow from './screens/Workflow Module Screen/Workflow/Workflow';
import WorkflowElement from './screens/Workflow Module Screen/Workflow Element/WorkflowElement';
import WorkFlowCanvas from './screens/Workflow Module Screen/React Flow/ReactFlow';
import GanttChart from './screens/Workflow Module Screen/Gantt Chart/GanttChart';
import Stages from './screens/Workflow Module Screen/Stages/Stages';
import Helper from './inc/Helper';
import Instances from './screens/Workflow Module Screen/Instances/Instances';
import EmailNotificationsArchieve from './screens/Workflow Module Screen/Email Notifications/EmailNotifications'

const PrivateRoutes = () => {
  const roles=Helper.getCookie('roles')
  const isAdmin=Helper.getCookie('isadmin')

  if(JSON.parse(isAdmin)){
    return(
      <Template/>
    )
  }
  else if(roles && (JSON.parse(roles).hasOwnProperty("79")||JSON.parse(roles).hasOwnProperty("71"))){
    return(
      <Navigate to="/workflow"/>
    )
  }
  else{
    return(
       <Navigate to="/workflow"/>
    )
  }
}

function App() {

  // Delete the token from cookies
  // const handleIdle = () => {
  //   const subdomainLocal = localStorage.getItem('subdomain')
  //   Helper.removeCookie(Settings.userTokenKey);
  //   window.location.href = `https://${subdomainLocal ? subdomainLocal : 'www'}.lynkaz.com/`
  // };

  // IdleTimer(30 * 60 * 1000, handleIdle);

  // let roles = Cookies.get('roles')
  // if(roles) {
  //   roles = JSON.parse(roles);
  // }

  // here all screens will rendered according to the routes!
  return (
      <Routes> 
          <Route path='/workflow' element={<Workflow />} />
          <Route path='/workflow-element' element={<WorkflowElement />}>
            <Route path=':workflow-element' element={<WorkFlowCanvas />} />
            <Route path=':ganttchart' element={<GanttChart />} />
          </Route>
          {/* <Route element={<PrivateRoutes />}> */}
             <Route path='/template' element={<Template />} />
          {/* </Route> */}
          <Route path='/template/edit' element={<FieldDictionary />} />
          <Route path='/stages' element={<Stages />} />
          <Route path='/instances' element={<Instances />} />
          <Route path='/workflow-pending-tasks' element={<WorkflowPendingTasks />} />
          <Route path="/email-notifications" element={<EmailNotificationsArchieve />} />
          <Route path='/dashboard' element={<Template />} />
          <Route path='/login' element={<Login />} />
          <Route path='/logout' element={<LogoutComponent />} />
          <Route path='/' element={<Login />} />
          <Route path='/register' element={<Register />} /> 
      </Routes>
  );
}

export default App;
