import React, { Component } from 'react';
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import Settings from '../../inc/Settings';
import Helper from '../../inc/Helper';
import ActionTypes from '../../inc/ActionsTypes';
import { connect } from 'react-redux';
import axios from 'axios';


class LanguageSwitch extends Component {
  constructor(pros){
    super(pros);
    this.isLoadedLanguage = false;
    this.state = {
    }
  }
  componentDidMount(){
    this.loadLangluage();
  }
  loadLangluage(){
    if(this.isLoadedLanguage){
      return;
    }
    if(this.props.language.allLanguageLoaded){
      return;
    }
    this.isLoadedLanguage = true;
    let that = this;
    axios.get(Settings.apiBaseUrl+"/language", { } ).then(function(res){
      if(res.data.status === 'success'){
          that.props.setLanguage(res.data.data);
          
      }
    });
  }
  changleLanguage(languageData){
    document.documentElement.setAttribute("lang", languageData.languageCode);
    Helper.setCookie(Settings.languageKey,JSON.stringify(languageData),100);
    this.props.setCurrentLang(languageData);
    this.loadLocalizationString(languageData.languange_Id);
  }
  loadLocalizationString(languageId){
    let that = this;
    axios.post(Settings.apiBaseUrl+"/get_labels", { "language_id": languageId } ).then(function(res){
      if(res.data.status==='success'){
          that.props.setAppStrings(res.data.data)
      }
    });
  }
  render() { 
    return (
      <div className="language-select">
        <div className="d-flex justify-content-end align-items-center language-select-root">
          <div className="dropdown">
            <button className="btn btn-link dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" >
              EN
            </button> 
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
      auth:state.auth,
      language:state.language
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
      setAppStrings:(labels) => { dispatch({type:ActionTypes.SET_LABELS , payload:labels}) },
      setLanguage:(languages) => { dispatch({type:ActionTypes.SET_LANGUAGE , payload:languages}) },
      setCurrentLang:(langData) => { dispatch({type:ActionTypes.SET_CURRENT_LANGUAGE , payload:langData}) },
  }
}
export default connect (mapStateToProps,mapDispatchToProps) ( LanguageSwitch );
