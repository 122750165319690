import React, {useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Api from '../../../inc/Api'
import Settings from '../../../inc/Settings'
import { getPopupMessageBasedOnStatus } from '../../../inc/Validation'
import Helper from '../../../inc/Helper'
import NavigationHeder from '../../../components/Navigations/NavigationHeder'
import AgGridMemo from '../../../components/grid/ag/AgGridMemo'
import MasterComonent from '../../../components/Backend/MasterComonent'

/* validation -> Event is mandatory field */

let forceRender = 0

const EmailNotificationsArchieve = () => {
  const [events, setEvents] = useState([])
  const [emailTemplates, setEmailTemplates] = useState([])
  const [teams, setTeams] = useState([])
  const [notificationsData, setNotificationsData] = useState([])

  let eventsCalled = false
  let emailTemplatesCalled = false

  useEffect(() => {
    getEvents()
    getTeamsforEmail()
    getEmailTemplates()
    getEmailNotificationData()
  }, [])

  const getEvents = () => {
    if (eventsCalled) return

    let api = Api
    eventsCalled = true
    api.setUserToken()
    api
      .axios()
      .get(Settings.apiUrl + `/events?workflow_events=true`)
      .then(function (res) {
        if (res.data.status === 'success') {
          setEvents(
            res?.data?.data?.map((element) => {
              return {
                value: element.event_id,
                label: element.event_name,
              }
            })
          )
          forceRender++
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res)
      })
  }

  const getTeamsforEmail = () => {
    let api = Api
    api.setUserToken()
    api
      .axios()
      .get(Settings.apiUrl + `/teamheader?dynamic_team_header=false`)
      .then(function (res) {
        if (res.data.status === 'success') {
          setTeams(
            res?.data?.data?.map((element) => {
              return {
                value: element.teamheader_id,
                label: element.name,
              }
            })
          )
          forceRender++
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res)
      })
  }

  const getEmailTemplates = () => {
    if (emailTemplatesCalled) return

    let api = Api
    emailTemplatesCalled = true
    api.setUserToken()
    api
      .axios()
      .get(Settings.apiUrl + '/emaildetails')
      .then(function (res) {
        if (res.data.status === 'success') {
          setEmailTemplates(
            res?.data?.data?.map((element) => {
              return {
                value: element.emaildetail_id,
                label: element.name ?? element.subject,
              }
            })
          )
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res)
      })
  }

  const getEmailNotificationData = () => {
    let api = Api
    api.setUserToken()
    api
      .axios()
      .get(Settings.apiUrl + '/triggernotification?workflow_events=true')
      .then(function (res) {
        if (res.data.status === 'success') {
          setNotificationsData(res.data.data)
          forceRender++
        }
      })
      .catch((res) => {
        getPopupMessageBasedOnStatus(res)
      })
  }

  const onRowValueChanged = (event) => {
    if (event?.data?.rec_id) {
      updateNotificationsEvents(event)
    } else {
      postNotificationsEvents(event)
    }
  }

  const updateNotificationsEvents = async (event) => {
    const {trigger_push_notification, trigger_email, emailtemplate_id}=event.data
    try {
      let payload = {
        trigger_push_notification: trigger_push_notification==="yes" || trigger_push_notification===true ? true : false,
        trigger_email: trigger_email==="yes" || trigger_email === true ? true : false,
        emailtemplate_id: emailtemplate_id ? parseInt(emailtemplate_id) : '',
      }

      let api = Api
      api.setUserToken()
      const res = await api.axios().put(Settings.apiUrl + `/triggernotification/${event?.data?.rec_id}`, payload)
      if (res.data.status === 'success') {
        getEmailNotificationData()
        Helper.alert(res?.data?.message)
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error)
    }
  }

  const postNotificationsEvents = async (event) => {
    try {
      let payload = {
        event_id: parseInt(event?.data?.event_id),
        trigger_push_notification: event?.data?.trigger_push_notification ? true : false,
        trigger_email: event?.data?.trigger_email ? true : false,
        emailtemplate_id: event?.data?.emailtemplate_id ? parseInt(event?.data?.emailtemplate_id) : null,
      }

      let api = Api
      api.setUserToken()
      const res = await api.axios().post(Settings.apiUrl + '/triggernotification', payload)
      if (res.data.status === 'success') {
        Helper.alert(res?.data?.message)
      }
    } catch (error) {
      getPopupMessageBasedOnStatus(error)
    }
  }

  const DeleteHandler = (allItems) => {
    let api = Api
    api.setUserToken()
    allItems.forEach(element => {
        api.axios().delete(Settings.apiUrl + `/triggernotification/${element.rec_id}`,{}).then(function(res){
            if(res.data.status==="success"){
              Helper.alert(res.data.message)
              getEmailNotificationData()
            }
        }).catch((res) => { 
          Helper.alert(res.response.data.message, 'error')
        })
    })
  }
  /* Grid Column Headers */

  const gridColumnHeaders = [
    {
      field: 'event_id',
      headerName: 'Event*',
      editable: (params) => !params?.data?.rec_id,
      inputType: 'dropdown',
      options: events,
    },
    {
      field: 'trigger_push_notification',
      editable: true,
      minWidth: 300,
      inputType: 'checkbox',
      headerName: 'Trigger push notification',
    },
    {
      field: 'emailtemplate_id',
      headerName: 'E-mail templates',
      editable: true,
      inputType: 'dropdown',
      options: emailTemplates,
      minWidth: 180,
    },
    {
      field: 'trigger_email',
      inputType: 'checkbox',
      editable: true,
      minWidth: 300,
      headerName: 'Trigger e-mail',
    },
    {
      field: 'cc_members',
      headerName: 'Email cc members',
      editable: true,
      minWidth: 180,
      inputType: 'dropdown',
      options: teams,
      cellEditorParams: { values: teams },
    },
  ]

  return (
    <div className='emails_template_archive'>
      <MasterComonent>
        <NavigationHeder title='Notifications' hideMoreBtn={true} ></NavigationHeder>

        <AgGridMemo
          pagination={false}
          forceRender={forceRender}
          header={gridColumnHeaders}
          data={notificationsData}
          hideSave={true}
          onRowValueChanged={onRowValueChanged}
          onDelete={DeleteHandler}
          height={500}
          uniqueField={`rec_id`}
          gridId={'rs_notification_event_list'}
        />
      </MasterComonent>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    appOptions: state.options,
    auth: state.auth,
    language: state.language,
  }
}

export default connect(mapStateToProps)(EmailNotificationsArchieve)
