import React, { Component } from "react";
import { Link } from "react-router-dom";
import Notifications from "./Notifications";
import SearchBox from "./SearchBox";
import UserWidget from "./UserWidget";
import './Header.css';
import LanguageSwitch from '../../components/Language Switcher/LanguageSwitch';
import ActionTypes from "../../inc/ActionsTypes";
import { connect } from "react-redux";
import Helper from "../../inc/Helper";
import $ from 'jquery';
class Header extends Component {
  constructor(props){
    super(props);
    this.stickyHeader = null;
  }
  componentDidMount(){
    //this.stickyHeader = new Sticky('.main_header');
    Helper.makeSticky('.main_header');
  }
  onSidebarIconClickHanlder(e){
    this.props.setOption('isSidebarOpen', !this.props.appOptions.options.isSidebarOpen)
    setTimeout(function(){
      Helper.refreshAppScript();
    },300)
    
  }
  getImageUrl (){
    if(!this?.props?.url?.logoUrl){
        return "/images/login-reg-screen-logo.png";
    }
    return this?.props?.url?.logoUrl;
  }
  componentDidUpdate(){
    if(this.props.appOptions.options.isSidebarOpen){
      $('body').addClass('main-sidebar-open');
    }else{
      $('body').removeClass('main-sidebar-open');
    }
  }
  render() {
    // data-sticky-class="sticky-active" data-sticky-wrap="true"
    return (
      <div className="main_header">
        <div className="main_header_inner">
          <div className="container-fluid">
            <div className="d-flex justify-content-between mh_inner">
              <div className="left_section"> 
                <img className='sidebar_toggle' src= { this.props.appOptions.options.isSidebarOpen ? "/images/icons/close-2.svg" : "/images/icons/menu.svg" } onClick={ e => { this.onSidebarIconClickHanlder(e) } } alt="" /> 
                <div style={{height:"40px", width:'100px'}}><img src={this?.getImageUrl()} alt="logo" className="vendor-reg-logo mx-0 w-100 h-100"/></div> 
              </div>
              <div className="d-flex right_section"> 
                <LanguageSwitch/> 
                <UserWidget/>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
      auth:state.auth,
      appOptions:state.options, 
      url: state.url
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
      setOption:(keyOption,valueOption) => { dispatch({type:ActionTypes.SET_OPTIONS ,  key: keyOption, value: valueOption }) },
  }
}
export default connect(mapStateToProps,mapDispatchToProps) (Header);
