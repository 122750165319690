import React from 'react';

const Pagination = ({ currentPage, totalPages, hidePaginationBtns = false, onPageChange, totalRecord }) => {
  const handleClick = (pageNumber) => {
    onPageChange(pageNumber);
  };

  const displayPaginationNumbers = () => {
    const tempAr = [];
    const pageRange = 2;
    const startPage = Math.max(2, currentPage - pageRange);
    const endPage = Math.min(totalPages - 1, currentPage + pageRange);
 
    tempAr.push(
      <li
        key={1}
        className={currentPage === 1 ? "page-item active" : "page-item"}
        onClick={currentPage !== 1 ? () => handleClick(1) : null}
      >
        <span className="page-link" style={{ cursor: "pointer" }}>1</span>
      </li>
    );
 
    if (startPage > 2) {
      tempAr.push(
        <li key="start-ellipsis" className="page-item disabled">
          <span className="page-link">...</span>
        </li>
      );
    }
 
    for (let i = startPage; i <= endPage; i++) {
      tempAr.push(
        <li
          key={i}
          className={currentPage === i ? "page-item active" : "page-item"}
          onClick={currentPage !== i ? () => handleClick(i) : null}
        >
          <span className="page-link" style={{ cursor: "pointer" }}>{i}</span>
        </li>
      );
    }
 
    if (endPage < totalPages - 1) {
      tempAr.push(
        <li key="end-ellipsis" className="page-item disabled">
          <span className="page-link">...</span>
        </li>
      );
    }
 
    if (totalPages > 1) {
      tempAr.push(
        <li
          key={totalPages}
          className={currentPage === totalPages ? "page-item active" : "page-item"}
          onClick={currentPage !== totalPages ? () => handleClick(totalPages) : null}
        >
          <span className="page-link" style={{ cursor: "pointer" }}>{totalPages}</span>
        </li>
      );
    }

    return tempAr;
  };

  return (
    <nav className="d-flex align-items-center rs_grid_pagination justify-content-between">
      <div>
        <p className="rs_grid_page_status">Showing 10 of {totalRecord} entries</p>
      </div>
      {!hidePaginationBtns && (
        <ul className="pagination"> 
          <li
            style={{ cursor: currentPage === 1 ? "not-allowed" : "pointer" }}
            className={`page-item nex_prev ${currentPage === 1 ? "disabled" : ""}`}
            onClick={currentPage !== 1 ? () => handleClick(currentPage - 1) : null}
          >
            <span className="page-link">&laquo;</span>
          </li>
           
          {displayPaginationNumbers()} 

          <li
            style={{ cursor: currentPage === totalPages ? "not-allowed" : "pointer" }}
            className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}
            onClick={currentPage !== totalPages ? () => handleClick(currentPage + 1) : null}
          >
            <span className="page-link">&raquo;</span>
          </li>
        </ul>
      )}
    </nav>
  );
};

export default Pagination;